import React from 'react';
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import BannerCrypto from '../components/centralized/Banner';
import Whatis from '../components/centralized/Whatis';
import DevService from '../components/centralized/DevServices';
import WhyChoose from '../components/centralized/WhyChoose';
import FaqSection from '../components/centralized/FaqSection';
import UserFriendly from '../components/Cryptoexchange/UserFriendly';
import Benificial from '../components/centralized/Benificial';
import TechStack from '../components/centralized/TechStack';
import DevProcess from '../components/centralized/DevProcess';
import FeatureBenefits from '../components/centralized/FeatureBenefits';



const Centralized = () => (
  <section className="cryptoex">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.coinsclone.com/centralized-exchange-development-company/" />
        <title>Centralized Exchange Development Company | Create Centralized Crypto Exchange</title>
        <meta name="description" content="Create a feature-rich centralized crypto exchange with Coinsclone, a leading company in centralized exchange development, prioritizing safe transactions and great user experience." />
        <meta name="keywords" content="Centralized Exchange Development Company, Centralized Exchange Development Services" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Centralized Exchange Development Company | Create Centralized Crypto Exchange" />
        <meta property="og:description" content="Create a feature-rich centralized crypto exchange with Coinsclone, a leading company in centralized exchange development, prioritizing safe transactions and great user experience." />
        <meta property="og:url" content="https://www.coinsclone.com/centralized-exchange-development-company/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/central/centralized-exchange-development-company.webp" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Create a feature-rich centralized crypto exchange with Coinsclone, a leading company in centralized exchange development, prioritizing safe transactions and great user experience." />
        <meta name="twitter:title" content="Centralized Exchange Development Company | Create Centralized Crypto Exchange" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/central/centralized-exchange-development-company.webp" />
      </Helmet>
      
      <BannerCrypto />
      <div className="breadcrumb wallet-banner mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | Centralized Exchange Development Company</span>
        </div>
      </div>
      <Whatis />
      <DevProcess />
      <Benificial />
      <FeatureBenefits />
      <DevService />
      <WhyChoose />
      <TechStack />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default Centralized

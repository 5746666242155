import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


const FeatureBenefits = () => {


  return (
    <section className="customs central pt-100 mb-0">
      <div className="container">
        <div className="row">
            <h2 className="heading-h2 text-center"><span className="bluecolor">Perks Of</span> Our Centralized Exchange Development Services
            </h2>
            <p className='text-center'>Experience streamlined trading with our centralized exchange development services. </p>
            <div className='d-flex flex-wrap justify-content-center top'>
                <div className='custom-blk'>
                    <p className="head3">User- Friendly</p>
                    <p class="pharagraph">Our Centralized Exchange platforms have intuitive interfaces that make it easy for users to explore the platform seamlessly. Your users can easily register and trade without hurdles. We thus increase your user participation with our easily navigating interface.</p>
                </div>
                <div className='custom-blk'>
                    <p className="head3">Speed and Liquidity</p>
                    <p class="pharagraph">As a Centralized Exchange Development Company, we prioritize speed and rapid transactions. Our development service will provide you with enhanced liquidity facilitation for your exchange platform. Thus enabling them to execute accurate trades at more favorable prices with speed.</p>
                </div>
                <div className='custom-blk'> 
                    <p className="head3">Reliability</p>
                    <p class="pharagraph">Our centralized exchange development services prioritize reliability to guarantee smooth operations without interruptions in the exchange. We implement multi-layer security and promptly address cyber threats, system failures, or any unexpected events.</p>
                </div>
                <div className='custom-blk'>
                    <p className="head3">Confidentiality</p>
                    <p class="pharagraph">The fundamental aspect of our centralized exchange development service is confidentiality. We ensure users' sensitive information is inaccessible to outside users. By safeguarding user data, we help you to build trust and confidence among your users.  </p>
                </div>
                <div className='custom-blk'>
                    <p className="head3">Direct Fiat Currency Exchange</p>
                    <p class="pharagraph">Our Centralized Crypto Exchange platform allows your user to trade with ease. We provide a fiat-to-crypto option for convenient trading on our platform. With direct fiat currency exchange, we streamline the process, lower costs, reduce market risks, and enhance accessibility for traders.</p>
                </div>
                <div className='custom-blk'>
                    <p className="head3">Legality and regulations</p>
                    <p class="pharagraph">Discover our centralized exchange development services, where legality and regulations take precedence. As a custom CEX development company, we ensure compliance with current laws, enhancing the credibility and sustainability of your exchange platform.</p>
                </div>
            </div>
        </div>
        <div className="mt-3 text-center">
                <ButtonComponent />
        </div>
      </div>
    </section>
  )
}

export default FeatureBenefits
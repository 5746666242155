import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class WhyChoose extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h4 className="heading-h2"><span className="bluecolor">What Makes Coinsclone </span>The Best Centralized Cryptocurrency Exchange Development Company?</h4>
              <p className="pharagraph">Kickstart your crypto business with Coinsclone, to stand out as the best-centralized exchange marketplace. We are renowned as the top centralized exchange development company through our dedicated services. Our talented developers will strike the perfect balance in crafting a top-tier centralized exchange for you. We assure to exhibit pure brilliance in every aspect.</p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/central/what-make-centralized.webp"
                alt="best centralized development company"
                placeholder='none'
                className='mt-3 mb-3'
                width={532}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph">From our modest beginnings, we've emerged as the pinnacle of excellence in this crypto industry. We build with supreme standards, providing a comfortable trading experience for your users. Our developer team is relentless, pushing the boundaries of possibility with each line of code. As a centralized exchange development company, our developers are the driving force behind every successful project - the true heroes of innovation. They're the powerhouse behind our success, infusing passion and expertise into every project they undertake.
              </p>
              <ul className='mt-3'>
                <li>100+ successful projects</li>
                <li>50+ well-trained blockchain professionals</li>
                <li>Advanced clone script products</li>
                <li>A team of certified professional experts</li>
                <li>Affordable Solution for Startups</li>
                <li>100% Transparency & Bug-free Solutions</li>
                <li>Customizable Projects</li>
                <li>Premium Level Tech Stack</li>
                <li>24/7 Maintenance and support </li>
                <li>On-time delivery</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose